<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    >
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="routeParams.idparte_trabajo"
        :idchecklist-ot="routeParams.idchecklist_ot"
        :idorden-trabajo-matsist="idsParaVolerAtras.idorden_trabajo_matsist"
        :idparte-trabajo-matsist="idsParaVolerAtras.idparte_trabajo_matsist"
        :idgrupo-checklist-ot="idsParaVolerAtras.idgrupoChecklistOt"
        :idsubsis="idsParaVolerAtras.idsubsis"
      />
    </b10-toolbar>
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
      />
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
      <b10-file-gallery
        v-if="hasViewPerm(permissions.anomaliaAdjunto.id)"
        class="pa-3"
        :images="ficherosGaleria"
        data-field="fichero.data"
        type-field="fichero.type"
        filename-field="fichero.nombre_fichero"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './AnomaliaViewData'
import { CHECKLIST, TABLA } from '@/utils/consts'
import colors from 'vuetify/lib/util/colors'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'

export default {
  components: {
    VolverParteChecklistMenuItems,
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        { name: 'tanomalia_checklist_ot.descripcion', label: 'Descripción' },
        { name: 'tanomalia_checklist_ot.descripcion_ampliada', label: 'Descripción ampliada' },
        { name: 'grado_anomalia.descripcion', label: 'Grado de anomalía' },
        { name: 'tanomalia_checklist_ot.mejora', label: 'Es una mejora', filter: this.$options.filters.humanizeBoolean },
        { name: 'tanomalia_checklist_ot.subsanado', label: 'Fue subsanada durante la revisión', filter: this.$options.filters.humanizeBoolean },
        { name: 'tanomalia_checklist_ot.notas_internas', label: 'Notas internas' },
        { name: 'tanomalia_checklist_ot.agregaradefinicioncheck', label: 'Agregar a la definición del checklist', filter: this.$options.filters.humanizeBoolean },
      ],
      toolbarOptions: {
        edit: {
          title: 'Modificar',
          visible: true,
          icon: 'edit'
        },
        delete: {
          title: 'Eliminar',
          visible: true,
          icon: 'delete'
        },
        addAccionCorrectora: {
          title: 'Añadir acción correctora',
          visible: true,
          icon: 'add'
        },
        addAdjunto: {
          title: 'Añadir adjunto',
          visible: true,
          icon: 'add'
        },
      },
      showingDialogs: {
        moreInfo: false,
      },
      ficherosGaleria: [],
      idsParaVolerAtras: {
        idorden_trabajo_matsist: null,
        idparte_trabajo_matsist: null,
        idgrupoChecklistOt: null,
        idsubsis: null,
      },
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.tanomalia_checklist_ot.idtanomalia_checklist_ot
        item.title = item.tanomalia_checklist_ot.descripcion
        item.subtitle = []
        if (item.tanomalia_checklist_ot.descripcion_ampliada) {
          item.subtitle.push(item.tanomalia_checklist_ot.descripcion_ampliada)
        }
        if (item.tanomalia_checklist_ot.notas_internas) {
          item.subtitle.push(item.tanomalia_checklist_ot.notas_internas)
        }
        if (item.tanomalia_checklist_ot.mejora) {
          item.subtitle.push('Es una mejora')
        }
        if (item.tanomalia_checklist_ot.subsanado) {
          item.subtitle.push('Fue subsanada durante la revisión')
        }
        item.moreInfo = this.buildMoreInfo()
        item.badge = item.grado_anomalia.descripcion
        item.badgeColor = colors.red.base
        return item
      } else {
        return {}
      }
    },
    estadoChecklistPendiente () {
      return this.item.dataset.checklist_ot.estado === CHECKLIST.estados.pendiente
    }
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = `Anomalía "${this.item.dataset.tanomalia_checklist_ot.descripcion}"`
      const datasetFicheros = await Data.selectFicheroAnomalia(this, this.routeParams.idtanomalia_checklist_ot)
      this.ficherosGaleria = datasetFicheros
      await this.loadDetailTotals()
      this.toolbarOptions.edit.visible = this.estadoChecklistPendiente
      this.toolbarOptions.delete.visible = this.estadoChecklistPendiente
      this.toolbarOptions.addAccionCorrectora.visible = this.estadoChecklistPendiente
      this.toolbarOptions.addAdjunto.visible = this.estadoChecklistPendiente
    },
    async loadItem () {
      this.loadingData = true
      try {
        const dataset = await Data.selectTanomaliaChecklistOt(this, this.routeParams.idtanomalia_checklist_ot)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          if (this.item.dataset.pregunta_checklist_ot.idpregunta_checklist_ot){
            const materialSistema = await this.$offline.preguntaChecklistOt.selectMaterialSistemaDePreguntaChecklistOt(
              this.item.dataset.pregunta_checklist_ot.idpregunta_checklist_ot
            )
            if (!!materialSistema)  {
              this.idsParaVolerAtras.idorden_trabajo_matsist = materialSistema.orden_trabajo_matsist.idorden_trabajo_matsist
              this.idsParaVolerAtras.idparte_trabajo_matsist = materialSistema.parte_trabajo_matsist.idparte_trabajo_matsist
              this.idsParaVolerAtras.idsubsis =  materialSistema.material_sistema.idsubsis
            }
          }
          this.idsParaVolerAtras.idgrupoChecklistOt = this.item.dataset.grupo_checklist_ot.idgrupo_checklist_ot
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      // acciones correctoras
      const detailAccionesCorrectoras = this.addDetail(
        'accionesCorrectoras', 'Acciones correctoras', '', 'accionCorrectora'
      )
      const datasetAccionesCorrectoras = await Data.selectAccionesCorrectorasCount(this, this.routeParams.idtanomalia_checklist_ot)
      detailAccionesCorrectoras.badge = datasetAccionesCorrectoras.count || 0
      // ficheros
      if (this.hasViewPerm(this.permissions.anomaliaAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'ficheros', 'Adjuntos', 'Fotografías y adjuntos de la anomalía', 'attach'
        )
        const datasetFicheros = await Data.selectFicherosCount(this, this.routeParams.idtanomalia_checklist_ot)
        detailFicheros.badge = datasetFicheros.count || 0
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'ficheros') {
        this.$appRouter.push({
          name: 'offline__anomalia-fichero-list',
          params: {
            idtabla: TABLA.tanomalia_checklist_ot.idtabla,
            id: this.routeParams.idtanomalia_checklist_ot,
            readonly: !this.estadoChecklistPendiente,
          },
        })
      } else if (data.detail.name === 'accionesCorrectoras') {
        this.$appRouter.push({
          name: 'offline__accion-correctora-list',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idchecklist_ot: this.routeParams.idchecklist_ot,
          },
          query: {
            idgrupo_checklist_ot: this.routeParams.idgrupo_checklist_ot,
            idpregunta_checklist_ot: this.routeParams.idpregunta_checklist_ot,
            idtanomalia_checklist_ot: this.routeParams.idtanomalia_checklist_ot
          },
        })
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.edit) {
        this.$appRouter.push({
          name: 'offline__anomalia-edit',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idchecklist_ot: this.routeParams.idchecklist_ot,
            idgrupo_checklist_ot: this.routeParams.idgrupo_checklist_ot,
            idpregunta_checklist_ot: this.routeParams.idpregunta_checklist_ot,
            idtanomalia_checklist_ot: this.routeParams.idtanomalia_checklist_ot
          }
        })
      } else if (option === this.toolbarOptions.delete) {
        this.deleteAnomalia()
      } else if (option === this.toolbarOptions.addAccionCorrectora) {
        this.$appRouter.push({
          name: 'offline__accion-correctora-add',
          params: {
            idparte_trabajo: this.routeParams.idparte_trabajo,
            idchecklist_ot: this.routeParams.idchecklist_ot,
            idgrupo_checklist_ot: this.routeParams.idgrupo_checklist_ot,
            idpregunta_checklist_ot: this.routeParams.idpregunta_checklist_ot,
            idtanomalia_checklist_ot: this.routeParams.idtanomalia_checklist_ot
          }
        })
      } else if (option === this.toolbarOptions.addAdjunto) {
        this.$appRouter.push({
          name: 'offline__fichero-add',
          params: {
            idtabla: TABLA.tanomalia_checklist_ot.idtabla,
            id: this.routeParams.idtanomalia_checklist_ot,
          }
        })
      }
    },
    async deleteAnomalia () {
      const res = await this.$alert.showConfirm(`¿Deseas eliminar la anomalía "${this.item.dataset.tanomalia_checklist_ot.descripcion}" y sus acciones correctoras?`)
      if (res) {
        await this.$offline.tanomaliaChecklistOt.deleteSync(
          this.item.dataset.tanomalia_checklist_ot.idtanomalia_checklist_ot
        )
        // actualizar el grado de anomalía de la pregunta
        // al máximo orden de los grados de anomalía de sus anomalías
        await this.$offline.preguntaChecklistOt.updateMaxGradoSync(
          this.item.dataset.pregunta_checklist_ot.idpregunta_checklist_ot, this.usuarioIdtecnico
        )
        await this.$dirty.deleted(this.$dirty.ENTITIES.local.tanomaliaChecklistOt, this.routeParams.idtanomalia_checklist_ot)
        await this.$dirty.modified(this.$dirty.ENTITIES.local.preguntaChecklistOt, this.item.dataset.pregunta_checklist_ot.idpregunta_checklist_ot)
        this.$appRouter.go(-1)
      }
    },
  },
}
</script>
